import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { allGames } from "../../allgames";

function Home() {
    const [show, setShow] = useState(false);
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperMenu = {
        autoplay: false,
        slidesPerView: 5.3,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        grabCursor: true
    };
    const swiperGame = {
        autoplay: true,
        slidesPerView: 3.6,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame1 = {
        autoplay: true,
        slidesPerView: 3.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: false,
        slidesPerView: 2.2,
        centerInsufficientSlides: false,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: false,
        slidesPerView: 1.1,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const searchParams = new URLSearchParams(window.location.search);
    const notifyParam = searchParams.get("notify");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(null);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const getProfile = () => {
        axios
            .get(url, {})
            .then((res) => {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        setTimeout(() => {
            axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
                setBet(res.data.t);
                if (bet != res.data.t) {
                    setBet(res.data.t);
                    localStorage.setItem("xsmb", JSON.stringify(res.data.t));
                }
            });
        }, 5000);
    }, []);
    const [activeOption, setActiveOption] = useState("0");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=m`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=m&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=m`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=m`;
                }
                setLoading(false);
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    if (url.length != 40) return;
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            {swiperParams && notify && (
                <AnimatedPage>
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                        {notify ? (
                            <>
                                {notify[0].isShow === true && notify[0].title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <CampaignIcon sx={{ fontSize: "22px" }} />
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify[0].content.length / 10 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div class="wallet-func">
                        <div class="wallet-func-item partner">
                            <Link to="/profile">
                                <img src={require("../../static/icon-menu.png")} />
                                <h3>Chức năng</h3>
                            </Link>
                        </div>
                        <div class="wallet-func-item partner">
                            <Link to="/service">
                                <img src={require("../../static/icon-partner.png")} />
                                <h3>CSKH</h3>
                            </Link>
                        </div>
                        <div class="wallet-func-item mobile">
                            <a href="#" target="_blank" rel="noreferrer">
                                <img src={require("../../static/icon_mobile.png")} />
                                <h3>Tải APP</h3>
                            </a>
                        </div>
                        <div class="wallet-func-item deposit">
                            <Link to="/recharge">
                                <img src={require("../../static/icon_deposit.png")} />
                                <h3>Nạp tiền</h3>
                            </Link>
                        </div>
                        <div class="wallet-func-item withdrawal">
                            <Link to="/withdraw">
                                <img src={require("../../static/icon_withdrawal.png")} />
                                <h3>Rút tiền</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="content-game-home">
                        <div className="content-game-left">
                            <div className="menu-game-home">
                                <div className={`menu-game-item ${activeOption === "0" ? "active" : ""}`} onClick={() => handleOptionClick("0")}>
                                    <img src={require("../../static/type_hot.png")} />
                                    <h3>Hot</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "9" ? "active" : ""}`} onClick={() => handleOptionClick("9")}>
                                    <img src={require("../../static/type_hotgame2_1.png")} />
                                    <h3>Games</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "1" ? "active" : ""}`} onClick={() => handleOptionClick("1")}>
                                    <img src={require("../../static/type_live.png")} />
                                    <h3>Casino</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "2" ? "active" : ""}`} onClick={() => handleOptionClick("2")}>
                                    <img src={require("../../static/type_egame.png")} />
                                    <h3>Nổ Hũ</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "3" ? "active" : ""}`} onClick={() => handleOptionClick("3")}>
                                    <img src={require("../../static/type_mpg.png")} />
                                    <h3>Bắn Cá</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                                    <img src={require("../../static/type_sport.png")} />
                                    <h3>Thể Thao</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "6" ? "active" : ""}`} onClick={() => handleOptionClick("6")}>
                                    <img src={require("../../static/type_chess.png")} />
                                    <h3>Game Bài</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "7" ? "active" : ""}`} onClick={() => handleOptionClick("7")}>
                                    <img src={require("../../static/type_lottery.png")} />
                                    <h3>Xổ Số</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "4" ? "active" : ""}`} onClick={() => handleOptionClick("4")}>
                                    <img src={require("../../static/type_animal.png")} />
                                    <h3>Khác</h3>
                                </div>
                            </div>
                        </div>
                        <div className="content-game-right">
                            {activeOption === "0" && (
                                <div className="api-game-flex2">
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "0" })}>
                                        <img alt="" src={require("../../static/popular/1.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1046" })}>
                                        <img alt="" src={require("../../static/popular/10.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "35" })}>
                                        <img alt="" src={require("../../static/popular/2.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1026" })}>
                                        <img alt="" src={require("../../static/popular/4.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1030" })}>
                                        <img alt="" src={require("../../static/popular/3.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1046" })}>
                                        <img alt="" src={require("../../static/popular/12.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "35" })}>
                                        <img alt="" src={require("../../static/popular/5.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1026" })}>
                                        <img alt="" src={require("../../static/popular/7.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "7" })}>
                                        <img alt="" src={require("../../static/popular/8.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1046" })}>
                                        <img alt="" src={require("../../static/popular/9.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "29" })}>
                                        <img alt="" src={require("../../static/popular/11.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "35" })}>
                                        <img alt="" src={require("../../static/popular/6.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "9" && (
                                <div className="api-game-flex">
                                    <Link className="api-game" to="/xd45s">
                                        <img alt="" src={require("../../static/xd1.png")} />
                                        <span>XÓC DĨA 45S</span>
                                    </Link>
                                    <Link className="api-game" to="/xd60s">
                                        <img alt="" src={require("../../static/xd2.png")} />
                                        <span>XÓC DĨA 60S</span>
                                    </Link>
                                    <Link className="api-game" to="/tx45s">
                                        <img alt="" src={require("../../static/tx1.png")} />
                                        <span>TÀI XỈU 45S</span>
                                    </Link>
                                    <Link className="api-game" to="/tx60s">
                                        <img alt="" src={require("../../static/tx2.png")} />
                                        <span>TÀI XỈU 60S</span>
                                    </Link>
                                </div>
                            )}
                            {activeOption === "1" && (
                                <div className="api-game-flex">
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "7" })}>
                                        <img alt="" src={require("../../images/games/live_ae.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "0" })}>
                                        <img alt="" src={require("../../images/games/live_wm.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1030" })}>
                                        <img alt="" src={require("../../images/games/live_dg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1064" })}>
                                        <img alt="" src={require("../../images/games/live_via.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1019" })}>
                                        <img alt="" src={require("../../images/games/live_yb.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1045" })}>
                                        <img alt="" src={require("../../images/games/live_miki.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "33" })}>
                                        <img alt="" src={require("../../images/games/live_gd.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1027" })}>
                                        <img alt="" src={require("../../images/games/live_mg.png")} />
                                        <div className="maintain" style={{ maxWidth: "calc(100% - 0.32rem)" }}>
                                            Đang bảo trì
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeOption === "2" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1026" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/R88.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1046" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/FC.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1044" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/PS.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "35" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/PG.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "16" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/Funky.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1031" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/Habanero.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "39" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/RedTiger.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1034" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/AP.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1040" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/nolimit.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "29" })} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/mg.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "3" && (
                                <div className="api-game-flex2">
                                    {allGames
                                        .filter((game) => game.gameType === 6 && game.newGameType === 203 && game.supportedCurrencies.includes("VND"))
                                        .map((item) => (
                                            <>
                                                {item.gameInfos[0].language != "ZH_CN" && (
                                                    <div
                                                        className="api-game border"
                                                        onClick={() => launchGame({ title: "SeamlessGame", gameId: item.gameID, gpid: item.gameProviderId })}>
                                                        <img alt={item.gameInfos[0].gameName} src={item.gameInfos[0].gameIconUrl} />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                </div>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1070" })}>
                                        <img alt="" src={require("../../images/games/ws168.png")} />
                                    </div>
                                    <div className="api-game-flex2">
                                        {allGames
                                            .filter((game) => game.gameType == 7 && game.supportedCurrencies.includes("VND"))
                                            .map((item) => (
                                                <>
                                                    {item.gameInfos[0].language != "ZH_CN" && (
                                                        <div
                                                            className="api-game border"
                                                            onClick={() => launchGame({ title: "SeamlessGame", gameId: item.gameID, gpid: item.gameProviderId })}>
                                                            <img alt={item.gameInfos[0].gameName} src={item.gameInfos[0].gameIconUrl} />
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                    </div>
                                    {/*<div className="api-game border" onClick={() => launchGame({ title: "VirtualSports" })}>
                                        <img alt="" src={require("../../images/games/sbo-esports.png")} />
                                    </div>*/}
                                </>
                            )}
                            {activeOption === "5" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => launchGame({ title: "SportsBook" })}>
                                        <img alt="" src={require("../../images/games/sbo1.png")} />
                                    </div>
                                    <div className="api-game">
                                        <img alt="" src={require("../../images/games/bti.png")} />
                                        <div className="maintain" style={{ maxWidth: "calc(100% - 0.32rem)" }}>
                                            Đang bảo trì
                                        </div>
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "ThirdPartySportsBook", gameId: "0", gpid: "1015" })}>
                                        <img alt="" src={require("../../images/games/fb.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ title: "VirtualSports" })}>
                                        <img alt="" src={require("../../images/games/sbo2.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "6" && (
                                <div className="api-game-flex2">
                                    {allGames
                                        .filter((game) => game.gameType === 8 && game.supportedCurrencies.includes("VND"))
                                        .map((item) => (
                                            <>
                                                {item.gameProviderId == 1009 && (
                                                    <div
                                                        className="api-game border"
                                                        onClick={() => launchGame({ title: "SeamlessGame", gameId: item.gameID, gpid: item.gameProviderId })}>
                                                        <img alt={item.gameInfos[0].gameName} src={item.gameInfos[0].gameIconUrl} />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                </div>
                            )}
                            {activeOption === "7" && (
                                <div className="list-game">
                                    <div className="box-miba">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                            <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")}>
                                                Đặt cược
                                            </div>
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text">
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                            <div className="box-miba-kq">
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title">Xổ số nhanh</div>
                                    <div className="list-game-xs">
                                        <Link to="/xs75s/lo2">
                                            <img alt="" src={require(`../../images/mbmg.png`)} />
                                        </Link>
                                        <Link to="/xs120s/lo2">
                                            <img alt="" src={require(`../../images/st2p.png`)} />
                                        </Link>
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Nam >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMN?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMN[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Trung >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMT?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMT[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ padding: "0 0.32rem 2rem" }}>
                        <div className="content-game">
                            {/*activeOption !== "1" && (
                                <div className="baohanh">
                                    <img alt="" src={require("../../images/bao-hanh-va-sua-chua.png")} style={{ maxWidth: "8rem" }} />
                                    <div className="nangcap">Hệ thống đang nâng cấp...</div>
                                </div>
                            )*/}
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer />
            {profile && notify && show && notifyParam != null && (
                <>
                    {notify.map(
                        (item) =>
                            item.isShow &&
                            item.title === "popup" && (
                                <div className="popup-backdrop">
                                    <div className="popup-main">
                                        <div className="popup-times" onClick={() => setShow(false)}>
                                            &times;
                                        </div>
                                        <div className="popup-content" style={{ padding: "20px" }}>
                                            {item.content.includes("{") && item.content.includes("}") ? (
                                                <img
                                                    src={item.content.substring(item.content.indexOf("{") + 1, item.content.indexOf("}"))}
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </>
            )}
        </>
    );
}
export default Home;
