import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AnimatedPage from "../components/AnimatedPage";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return false;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return false;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return false;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    localStorage.setItem("password", data.password);
                    navigate("/?notify");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login">
            <div onClick={() => navigate("/")} className="login-return">
                <CloseIcon className="login-return-i" />
            </div>
            <img src={require("../../static/logo.png")} style={{ height: "135px", width: "auto", margin: "50px 0 0" }} />
            <div style={{ display: "flex", padding: "20px 20px", flexFlow: "row wrap", alignItems: "center" }}>
                <div style={{ width: "50%", textAlign: "left", color: "#000" }}>
                    <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>Đại Sứ Thương Hiệu</h2>
                    <p style={{ fontSize: "13px" }}>
                        Andrea Pirlo
                        <br />
                        Roberto Carlos (2023-2024)
                    </p>
                </div>
                <div style={{ width: "50%" }}>
                    <img src={require("../../static/footer-endorser-logo1.png")} style={{ width: "100%" }} />
                </div>
            </div>
            {/*<div style={{ display: "flex", padding: "0 20px 20px", flexFlow: "row wrap", alignItems: "center" }}>
                <div style={{ width: "50%", textAlign: "left", color: "#000" }}>
                    <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>Đối Tác Chính Thức</h2>
                    <p style={{ fontSize: "13px" }}>Villarreal CF & OKVIP</p>
                </div>
                <div style={{ width: "50%", display: "flex", justifyContent: "space-between" }}>
                    <img src={require("../../static/footer-endorser-logo2.png")} style={{ height: "50px" }} />
                    <div style={{ background: "#00abe5", width: "1px" }}></div>
                    <img src={require("../../static/footer-endorser-logo3.png")} style={{ height: "50px" }} />
                </div>
            </div>*/}
            <AnimatedPage>
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)} style={{ margin: "0" }}>
                    <div className="inputs">
                        <div className="input">
                            <img alt="" src={require("../../static/icon-login-id.png")} />
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className="input">
                            <img alt="" src={require("../../static/icon-login-lock.png")} />
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p>{err}</p> : null}
                    <div className="p-lg" style={{ color: "#333", textAlign: "left" }}>
                        ⚠️ Chú ý: Hiện tại xuất hiện nhiều đường link giả mạo đánh cắp thông tin người chơi, nếu quý khách vừa đăng nhập đường link không rõ ràng vui lòng hãy đổi
                        mật khẩu của mình sau khi đăng nhập để bảo đảm an toàn cho tài sản của mình.
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "0 20px" }}>
                        <button
                            className="btn-red-big"
                            type="button"
                            style={{ background: "#fff", border: "1px solid #00aae6", color: "#00aae6" }}
                            onClick={() => navigate("/register")}>
                            ĐĂNG KÝ NGAY
                        </button>
                        <button className="btn-red-big" type="submit">
                            ĐĂNG NHẬP
                        </button>
                    </div>
                </form>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "20px", textAlign: "center", width: "100%" }}>
                    <div>
                        <h3 style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>LẤY LẠI MẬT KHẨU</h3>
                        <div style={{ display: "flex", justifyContent: "center", gap: "20px", fontSize: "11px", color: "#4d4d4d" }}>
                            <div>
                                <img alt="" src={require("../../static/login-mail.png")} style={{ height: "40px" }} />
                                <br />
                                EMAIL
                            </div>
                            <div>
                                <img alt="" src={require("../../static/login-sms.png")} style={{ height: "40px" }} />
                                <br />
                                SMS
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>TẢI APP</h3>
                        <div style={{ display: "flex", justifyContent: "center", gap: "20px", fontSize: "11px", color: "#4d4d4d" }}>
                            <div>
                                <img alt="" src={require("../../static/login-googleplay.png")} style={{ height: "40px" }} />
                                <br />
                                GOOGLE PLAY
                            </div>
                            <div>
                                <img alt="" src={require("../../static/login-appstore.png")} style={{ height: "40px" }} />
                                <br />
                                APP STORE
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    );
}
export default Login;
