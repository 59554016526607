import { useEffect, useState } from "react";
//import LiveChat from "react-livechat";
import Footer from "../components/Footer";
import axios from "axios";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { useNavigate } from "react-router-dom";

function CSKH() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    //const [isShow, setShow] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">CSKH</h1>
            </div>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="main" style={{overflow:"hidden"}}>
                <img
                    src={require("../../img/CSKH-cover-blue@2x.7365166c.png")}
                    style={{ display: "block", margin: "0 -0.6rem", width: "calc(100% + 1.2rem)", maxWidth: "calc(100% + 1.2rem)" }}
                />
                <div style={{ position: "relative", height: "70vh" }}>
                    <a href={setting?.cskh} target="_blank" className="btn-service">
                        <span className="service-icon"></span>CSKH 1
                    </a>
                    {setting?.cskh2 && setting?.cskh2 != "" && (
                        <>
                            <br />
                            <a href={setting?.cskh2} target="_blank" className="btn-service">
                                <span className="service-icon"></span>CSKH 2
                            </a>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CSKH;
